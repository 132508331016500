import React from 'react';

import Meta from '../components/meta';

import { Link } from 'gatsby';

import Contents from '../components/contents';

const Security = () =>{



    return(
        <>

            <Meta
                title="情報セキュリティ基本方針"
                description="アクロスソリューションズの情報セキュリティ基本方針について"
            />

            <Contents>

                <article className="p-recruit-jobs is-policy">

                    <section className="p-recruit-jobs-section">

                        <div className="p-recruit-section-box is-detail">
                            <div className="p-recruit-job">
                                <p className="p-recruit-job--text">
                               情報セキュリティ基本方針
                                </p>
                            </div>

                            <div className="p-recruit-data">

                                <div className="p-recruit-data-box">
                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        当社は、電子商取引に係る「プロフェッショナル」として、ICTソリューション事業と販促ソリューション事業を展開する事で、お客様の事業の改善やビジネス戦略立案に貢献し、またお客様の事業発展を通じて社会に貢献することを経営理念としています。当社において情報セキュリティへの取り組みは、この経営理念を実現する重要な施策の一つとして位置付け、情報資産の機密性・完全性・可用性を確実に確保することによって、お客様との確かな信頼を築き、お客様への情報技術に関するより付加価値の高いサービス提供に努めることを目的として、情報セキュリティ基本方針を定め、実践していくことを宣言します。
                                        </p>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <div className="p-recruit-data-box-cnt">

                                        <div className="p-recruit-data-box-list">
                                            <p className="p-recruit-data-box-list--title">
                                            1.（適用範囲）
                                            </p>

                                            <p className="p-recruit-data-box-list--text">
                                            この基本方針が対象とする「情報資産」とは、当社が事業活動で知りえた全ての情報資産とし、この情報資産を取扱う全ての役員、従業員、協力会社社員等に適用します。
                                            </p>
                                        </div>

                                        <div className="p-recruit-data-box-list">
                                            <p className="p-recruit-data-box-list--title">
                                            2.（体制整備）
                                            </p>

                                            <p className="p-recruit-data-box-list--text">
                                            組織的な情報セキュリティへの取り組みを実現するための管理体制を整備します。
                                            </p>
                                        </div>

                                        <div className="p-recruit-data-box-list">
                                            <p className="p-recruit-data-box-list--title">
                                            3.（法令等遵守）
                                            </p>

                                            <p className="p-recruit-data-box-list--text">
                                            情報セキュリティに関する法令及びその他規則並びにお客様との契約上のセキュリティ事項を遵守します。
                                            </p>
                                        </div>

                                        <div className="p-recruit-data-box-list">
                                            <p className="p-recruit-data-box-list--title">
                                            4.（教育）
                                            </p>

                                            <p className="p-recruit-data-box-list--text">
                                            情報セキュリティ教育を継続的に実施し、当社セキュリティレベルの維持・向上に努めます。
                                            </p>
                                        </div>

                                        <div className="p-recruit-data-box-list">
                                            <p className="p-recruit-data-box-list--title">
                                            5.（監査）
                                            </p>

                                            <p className="p-recruit-data-box-list--text">
                                            当社の情報セキュリティへの取り組みを客観的に評価・検証するため、内部監査を実施します。
                                            </p>
                                        </div>

                                        <div className="p-recruit-data-box-list">
                                            <p className="p-recruit-data-box-list--title">
                                            6.（継続的改善）
                                            </p>

                                            <p className="p-recruit-data-box-list--text">
                                            監査結果の分析およびリスクの認識・評価等を実施することにより、継続的な改善活動を実施します。
                                            </p>
                                        </div>

                                        <div className="p-recruit-data-box-list">
                                            <p className="p-recruit-data-box-list--title">
                                            7.（インシデント対応）
                                            </p>

                                            <p className="p-recruit-data-box-list--text">
                                            インシデント発生時には、お客様の情報資産保全を最優先に迅速に対応します。
                                            </p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-recruit-data-box-sign">
                            <p className="p-recruit-data-box-cnt--text">
                            2023年7月1日<br />
                            株式会社アクロスソリューションズ<br />
                            代表取締役　能登 満
                            </p>
                        </div>
                    </section>

                </article>

            </Contents>

        </>
    )
}

export default Security;


